
<template>
  <moe-page title="签到设置">
    <moe-card class="mb-20" :autoHeight="true" title="日历签到">
      <moe-form
        class="default-form"
        ref="signForm"
        :showBack="false"
        :model="signParmas"
        :rules="rules">
        <el-form-item label="活动状态" prop="state">
          <moe-radio-group v-model="signParmas.state" :radioBoxList="$moe_data.signStateList"></moe-radio-group>
        </el-form-item>

        <div class="fwb mb-20 font-20">连续签到奖励</div>
        <div style="height: 700px;min-height: 700px;" class="df fdc">
          <moe-table
            :stripe="true"
            :data="signParmas.itemList"
            :key="itemKey"
            :numberShow="false"
            :mode="false"
            emptyText="请添加签到奖励">
            <el-form-item prop="itemList" slot="tool">
              <el-button type="primary" icon="el-icon-circle-plus-outline" @click="form.showDialog = true;">新增连签奖励</el-button>
            </el-form-item>

            <el-table-column label="连续天数" min-width="200">
              <template slot-scope="{ row, $index }">
                <div class="df aic jcc mt-20">
                  <el-form-item label-width="0px" :prop="`itemList.${$index}.signDays`" :rules="rules.signDays">
                    <div class="w-150">{{ `${row.signDays}天` }}</div>
                  </el-form-item>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="连签奖励" prop="" min-width="200" :formatter="formatterPrizeType" />
            <el-table-column label="领取限制" prop="" min-width="200" :formatter="formatterQuote" />
            <el-table-column label="操作" fixed="right" width="200">
              <template slot-scope="{ row, $index }">
                <el-button icon="el-icon-edit" size="mini" type="primary" @click="handleEdit(row, $index)">编辑</el-button>
                <el-button icon="el-icon-delete" size="small" type="danger" @click="handleDelete('signForm', row, $index)">删除</el-button>
              </template>
            </el-table-column>
          </moe-table>
        </div>

        <el-form-item label="规则说明" prop="description">
          <moe-textarea
            class="w-500"
            v-model="signParmas.description"
            :autosize="{ minRows: 4, maxRows: 6 }"
            placeholder="输入规则说明"
            maxlength="300">
          </moe-textarea>
        </el-form-item>

        <template slot="tool">
          <el-button type="primary" icon="el-icon-thumb" :loading="signLoad" @click="handleSubmit()">{{ signLoad ? '保存中...' : '保存' }}</el-button>
        </template>
      </moe-form>
    </moe-card>

    <moe-dialog :show="form.showDialog" title="设置连签奖励" width="70%" @close="handleDialogClose()">
      <div class="dialog_content">
        <moe-form
          class="default-form"
          ref="prizeForm"
          :showback="false"
          :showTool="false"
          :model="form"
          :rules="formRules">
          <el-form-item label="连续签到" prop="signDays">
            <el-input
              class="w-200"
              :value="form.signDays"
              placeholder="请输入"
              maxLength="6"
              @input="(value) => form.signDays = $moe_formatter.formatterInteger(value)">
              <template slot="append">天</template>
            </el-input>
          </el-form-item>
          <el-form-item label="活动内容" prop="prizeType">
            <moe-radio-group :radioBoxList="prizeTypeList" v-model="form.prizeType" @change="changePrizeType"></moe-radio-group>
          </el-form-item>
          <div style="height: 300px;min-height: 300px;" class="df fdc">
            <moe-table
              :numberShow="false"
              :data="form.itemList"
              :mode="false">
              <el-form-item prop="itemList" slot="tool">
                <el-button
                  :disabled="form.itemList.length ? true : false"
                  type="primary"
                  icon="el-icon-circle-plus-outline"
                  @click="handleShowDialog()">
                  {{ `添加${$moe_format.getPrizeTypeList(form.prizeType)}` }}
                </el-button>
              </el-form-item>
              <template v-if="form.prizeType === 'COUPON'">
                <el-table-column label="活动信息" min-width="150">
                  <template slot-scope="{ row }">
                    <div>{{ row.name }}</div>
                    <div>券编号:{{ row.id }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="优惠方式" min-width="150" :formatter="formatterMethod" />
                <!-- <el-table-column label="发放量" min-width="100" prop="number" />
                <el-table-column label="领取量" min-width="100" prop="received" />
                <el-table-column label="使用量" min-width="100" prop="used" /> -->
              </template>
              <template v-else-if="form.prizeType === 'GIFT'">
                <el-table-column label="赠品名称" prop="name" min-width="150" :show-overflow-tooltip="true" />
                <el-table-column label="赠品信息" min-width="200">
                  <template slot-scope="{ row }">
                    <div class="df aic jcc">
                      <moe-image :src="row.goodsItemCover" width="80px" height="80px" />
                      <div class="df1 tal ml-10">
                        <div class="fwb">{{ row.goodsName }}</div>
                        <div class="df aic">
                          <moe-tag class="mr-5" v-for="(specItem, specIndex) in row.goodsItemName.split(',')" :key="specIndex">{{ specItem }}</moe-tag>
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="剩余库存" prop="stockLeft" min-width="100" />
              </template>
              <el-table-column label="操作" fixed="right" width="200">
                <template slot-scope="{ row, $index }">
                  <el-button icon="el-icon-delete" size="small" type="danger" @click="handleDelete('prizeForm', row, $index)">删除</el-button>
                </template>
              </el-table-column>
            </moe-table>
          </div>
          <el-form-item label="领取限制" prop="quoteRadio">
            <moe-radio-group :radioBoxList="[{ label: '不限制', value: false }, { label: '每人限领', value: true }]" v-model="form.quoteRadio"></moe-radio-group>
          </el-form-item>
          <el-form-item label="" prop="quote" v-if="form.quoteRadio">
            <el-input
              class="w-200"
              :value="form.quote"
              placeholder="请输入"
              maxLength="6"
              @input="(value) => form.quote = $moe_formatter.formatterInteger(value)">
              <template slot="append">次</template>
            </el-input>
          </el-form-item>
        </moe-form>
      </div>
      <template slot="footer">
        <el-button :loading="signLoad" @click="handleDialogClose()">{{ signLoad ? '请稍候...' : '取消' }}</el-button>
        <el-button :loading="signLoad" type="primary" @click="handleConfirm()">{{ signLoad ? '请稍候...' : '确定' }}</el-button>
      </template>
    </moe-dialog>

    <couponDialog
      :showDialog.sync="couponParams.showDialog"
      :multiple="false"
      @close="handleCouponClose">
    </couponDialog>

    <giftGoodsDialog
      :showDialog.sync="giftParams.showDialog"
      :multiple="false"
      @close="handleGiftClose">
    </giftGoodsDialog>
  </moe-page>
</template>

<script>
import couponDialog from '@/views/ActivityManage/components/couponDialog.vue';
import giftGoodsDialog from '@/views/ActivityManage/components/giftGoodsDialog.vue';
export default {
  name: 'SignManageSet',
  components: {
    couponDialog,
    giftGoodsDialog
  },
  computed: {
    prizeTypeList() {
      return this.$moe_data.prizeTypeList.slice(0, 1);
    }
  },
  data() {
    const verify = this.$moe_verify.verifyForm;
    const checkSignDays = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(`请输入连签天数`));
      } else {
        if (this.signParmas.itemList.filter(({ signDays }) => this.$moe_math.mathEquals(signDays, value)).length > 1) {
          return callback(new Error(`存在相同的连签天数`));
        }
        callback();
      }
    }
    const checkItemList = (rule, value, callback) => {
      const { prizeType } = this.form;
      if (!value.length) {
        if (prizeType === 'COUPON') {
          return callback(new Error(`请添加优惠券`));
        }
        if (prizeType === 'GIFT') {
          return callback(new Error(`请添加赠品`));
        }
      } else {
        callback();
      }
    }
    const checkQuote = (rule, value, callback) => {
      const { quoteRadio } = this.form;
      if (quoteRadio) {
        if (this.$moe_math.mathLessThanOrEqualTo(value, 0)) {
          return callback(new Error(`每人限领必须大于0`));
        } else if (!value) {
          return callback(new Error(`请输入每人限领次数`));
        } else {
          callback();
        }
      }
      callback();
    }
    return {
      signParmas: {
        state: false,
        itemList: [],
        description: '',
      },
      rules: {
        state: verify.isEmpty('请选择活动状态'),
        itemList: verify.isEmpty('请添加签到奖励'),
        signDays: [{ required: true, validator: checkSignDays, trigger: ['blur', 'change'] }],
      },
      formRules: {
        signDays: verify.isEmpty('请输入连续签到天数'),
        prizeType: verify.isEmpty('请选择活动内容'),
        itemList: [{ required: true, validator: checkItemList, trigger: ['blur', 'change'] }],
        quote: [{ required: true, validator: checkQuote, trigger: ['blur', 'change'] }],
      },
      signLoad: false,
      form: {
        showDialog: false,
        index: null,
        id: '',
        signDays: '',
        prizeType: 'COUPON',
        itemList: [],
        quoteRadio: true,
        quote: '',
      },
      couponParams: {
        showDialog: false,
      },
      giftParams: {
        showDialog: false,
      },
      itemKey: Math.random(),
    }
  },
  methods: {
    formatterPrizeType({ prizeType, itemList }) {
      return `${this.$moe_format.getPrizeTypeList(prizeType)}: 签到${prizeType === 'COUPON' ? `${itemList[0]?.discount}元专属优惠券` : `赠${itemList[0]?.name}`}`;
    },
    formatterQuote({ quote }) {
      return this.$moe_math.mathGreaterThan(quote, 0) ? `每人限制领取${quote}次` : `不限领`;
    },
    formatterMethod({ condition, discount, name }) {
      const { prizeType } = this.form;
      if (prizeType === 'COUPON') {
        return `满 ${condition} 减 ${discount} 元`;
      } else if (prizeType === 'GIFT') {
        return `${name}`;
      } else {
        return '-';
      }
    },
    handleDialogClose() {
      this.form = {
        showDialog: false,
        index: null,
        id: '',
        signDays: '',
        prizeType: 'COUPON',
        itemList: [],
        quoteRadio: true,
        quote: '',
      }
      this.signLoad = false;
      try {
        this.$refs['prizeForm'].resetFields();
      } catch {
        console.log('error');
      }
    },
    changePrizeType() {
      if (this.form.itemList.length) {
        this.form.itemList.splice(0);
        this.$moe_msg.warning('检测到你切换奖品内容 已清除内容');
      }
    },
    handleConfirm() {
      this.$refs['prizeForm'].validate(() => {
        this.signLoad = true;
        const { id, index, signDays, prizeType, itemList, quoteRadio, quote } = this.form;
        let item = {
          id: id || '',
          signDays,
          prizeType,
          prizeId: itemList[0].id,
          itemList,
          quote: quoteRadio ? quote : 0,
        }
        if (index === null) {
          this.signParmas.itemList.push(item);
        } else {
          this.signParmas.itemList[index] = this.$moe_lodash.cloneDeepData(item);
        }
        this.itemKey = Math.random();
        this.handleDialogClose();
        this.$nextTick(() => {
          try {
            this.$refs['signForm'].validate();
          } catch {
            console.log('error');
          }
        })
      })
    },
    handleEdit({ id, signDays, prizeType, itemList, quote }, index) {
      this.form = {
        showDialog: true,
        index,
        id: id || '',
        signDays,
        prizeType,
        itemList,
        quoteRadio: this.$moe_math.mathGreaterThan(quote, 0),
        quote,
      };
    },
    /** 删除添加的奖品 */
    handleDelete(source, item, index) {
      this.$moe_layer.confirm(`您确定要删除当前选项吗？`, () => {
        switch (source) {
          case 'signForm':
            this.signParmas.itemList.splice(index, 1);
            this.$nextTick(() => {
              try {
                this.$refs['signForm'].validate();
              } catch {
                console.log('error');
              }
            })
            break;
          case 'prizeForm':
            this.form.itemList.splice(index, 1);
            break;
        }
        this.$moe_msg.success('删除成功');
      });
    },
    handleShowDialog() {
      const { prizeType } = this.form;
      switch (prizeType) {
        case 'COUPON':
          this.couponParams.showDialog = true;
          break;
        case 'GIFT':
          this.giftParams.showDialog = true;
          break;
      }
    },
    /** 选择优惠券确定 */
    handleCouponClose(selectList) {
      this.form.itemList = this.$moe_lodash.cloneDeepData(selectList);
      this.couponParams.showDialog = false;
    },
    /** 选择赠品确定 */
    handleGiftClose(selectList) {
      this.form.itemList = this.$moe_lodash.cloneDeepData(selectList);
      this.giftParams.showDialog = false;
    },
    handleSubmit() {
      this.$refs['signForm'].validate(() => {
        this.signLoad = true;
        const { state, itemList, description } = this.signParmas;
        let params = {
          state,
          itemList: itemList.map(({ id, prizeId, prizeType, quote, signDays }) => {
            return {
              id,
              prizeId,
              prizeType,
              quote,
              signDays
            }
          }),
          description
        };
        this.req_updateSign(params);
      });
    },
    /** 更新签到活动信息 */
    async req_updateSign(params) {
      const { code, result, message } = await this.$moe_api.SIGN_API.updateSign(params);
      if (code === 200) {
        this.$moe_msg.success('保存成功', {
          completionHandler: () => {
            this.signLoad = false;
            this.req_getSignDetail();
          }
        })
      } else {
        this.signLoad = false;
        this.$moe_msg.error(message);
      }
    },
    /** 获取签到活动信息 */
    async req_getSignDetail() {
      const { code, result, message } = await this.$moe_api.SIGN_API.getSignDetail();
      if (code === 200) {
        const { state, itemList, description } = result;
        this.signParmas = {
          state,
          itemList: itemList?.length ? itemList.map((item) => {
            return {
              ...item,
              itemList: []
            }
          }) : [],
          description
        };
        if (itemList?.length) {
          let couponList = itemList.filter(({ prizeType }) => prizeType === 'COUPON'),
            giftList = itemList.filter(({ prizeType }) => prizeType === 'GIFT');
          if (couponList.length) {
            this.req_getCouponListByIds(couponList);
          }
          if (giftList.length) {
            this.req_getGiftListByIds(giftList);
          }
        }
      } else {
        this.$moe_msg.error(message);
      }
    },
    /** 批量获取优惠券详情 */
    async req_getCouponListByIds(couponList) {
      const { code, result, message } = await this.$moe_api.COUPON_API.getCouponListByIds({ ids: couponList.map(({ prizeId }) => prizeId).join(',') });
      if (code === 200) {
        this.signParmas.itemList.forEach((item) => {
          let findList = result.list.filter(({ id }) => item.prizeId === id);
          if (findList.length) {
            item.itemList = findList;
          }
        })
      } else {
        this.$moe_msg.error(message);
      }
    },
    /** 批量获取赠品详情 */
    async req_getGiftListByIds(giftList) {
      const { code, result, message } = await this.$moe_api.GIFTMANAGE_API.getGiftListByIds({ ids: giftList.map(({ prizeId }) => prizeId).join(',') });
      if (code === 200) {
        this.signParmas.itemList.forEach((item) => {
          let findList = result.list.filter(({ id }) => item.prizeId === id);
          if (findList.length) {
            item.itemList = findList;
          }
        })
      } else {
        this.$moe_msg.error(message);
      }
    },
  },
  mounted() {
    this.req_getSignDetail();
  }
}
</script>